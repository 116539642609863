import gql from 'graphql-tag';
import {Query} from "@deity/falcon-ecommerce-uikit";


export const GET_ORDER_MASK = gql`
  query MaskedOrder($maskedId: String) {
    maskedOrder(maskedId: $maskedId) {
      entityId
      incrementId
      createdAt
      customerFirstname
      customerLastname
      status
      subtotal
      shippingAmount
      shippingInclTax
      subtotalInclTax
      grandTotal
      orderCurrencyCode
      shippingDescription
      paymentMethodName
      payment {
        method
        ccLast4
        ccType
      }
      items {
        itemId
        sku
        name
        rowTotalInclTax
        qty
        thumbnailUrl
        link
        manufacturer
        shortDescription
      }
      billingAddress {
        company
        firstname
        lastname
        street
        city
        postcode
        countryId
        telephone
      }
      shippingAddress {
        company
        firstname
        lastname
        street
        city
        postcode
        countryId
        telephone
      }
      invoices {
        url
        id
      }
      shipments {
        id
        trackNumber
        items {
          qty
          itemId
        }
      }
    }
  }
`;

export class MaskedOrderQuery extends Query {
  static defaultProps = {
    query: GET_ORDER_MASK
  };
}
