import React from "react";
import Helmet from "react-helmet";
import { Shipment } from "benlux-ecommerce-ui";

import { MaskedOrderQuery } from "../../../../queries/MaskedOrderQuery";

const OrderWithMask = ({ match }) => {
  return (
    <MaskedOrderQuery variables={{ maskedId: match.params.maskedId }}>
      {({ maskedOrder }) => {
        return (
          <>
            <Helmet>
              <title>Suivi de commande</title>
              <meta name="page-loaded" content="true" />
            </Helmet>
            <Shipment order={maskedOrder} />
          </>
        );
      }}
    </MaskedOrderQuery>
  );
};

export default OrderWithMask;
